import { EchoEnv, useInitial } from '@equinor/echo-core';
import { EchoContent, EchoEventHandler, EchoRoutes, Toasters } from '@equinor/echo-framework';
import { Syncer } from '@equinor/echo-search';
import { fireAndForget } from '@equinor/echo-utils';
import React from 'react';
import { Redirect as TypedRedirect, Route as TypedRoute, Switch as TypedSwitch } from 'react-router-dom';
import { getCorePlants } from './api/api-plants';
import './app.css';
import { Home } from './components/Home/Home';
import { Legend } from './components/legend';

export const EchoApp: React.FC = () => {
    useInitial(async () => {
        await Syncer.configuration.setApiBaseUrl(EchoEnv.env().REACT_APP_API_URL);
        fireAndForget(getCorePlants);
    });

    return (
        <EchoEventHandler>
            <EchoContent Legend={Legend} isOnboardingCompleted={true}>
                <TypedSwitch>
                    <EchoRoutes homeComponent={Home} />
                    <TypedRoute render={(): JSX.Element => <TypedRedirect to="/" />} />
                </TypedSwitch>
                <Toasters />
            </EchoContent>
        </EchoEventHandler>
    );
};
